<template>
  <el-dialog
    title="申请记录详情"
    :visible.sync="dialogVisible2"
    :before-close="handleClose"
    width="70%"
    height="1300px"
  >
    <div class="LoanDetails">
      <table border="1" cellpadding="0">
        <tbody>
          <tr>
            <td>申请人</td>
            <td>{{ historyList.name }}</td>
            <td>地址</td>
            <td>{{ historyList.area }}</td>
          </tr>

          <tr>
            <td>身份证号</td>
            <td>{{ historyList.idCardNo }}</td>
            <td>手机号</td>
            <td>{{ historyList.phone }}</td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td>年经营收入</td>
            <td>{{ historyList.income }}</td>
            <td>实际经营年限</td>
            <td>{{ historyList.operatYear }}年</td>
          </tr>

          <tr v-for="(item, index) in historyList.operatList" :key="index">
            <td>种养品种</td>
            <td>{{ item.operatCrop }}</td>
            <td>规模</td>
            <td>{{ item.operatCount }}{{ item.operatUnit }}</td>
          </tr>
          <tr>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td>贷款金额</td>
            <td colspan="3">{{ historyList.money }}</td>
          </tr>

          <tr>
            <td>资金用途</td>
            <td colspan="3">{{ historyList.use }}</td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>

          <tr>
            <td>审批状态</td>
            <td>{{ historyList.status }}</td>
            <td v-if="IsStatus == '1'">申请时间</td>
            <td v-if="IsStatus == '1'">{{ historyList.approveTime }}</td>

            <td v-if="IsStatus == '-1'">驳回原因</td>
            <td v-if="IsStatus == '-1'">{{ historyList.failRemark }}</td>

            <td v-if="IsStatus == '2'">批准时间</td>
            <td v-if="IsStatus == '2'">{{ historyList.approveTime }}</td>
          </tr>
          <tr v-if="IsStatus == '2'">
            <td>批准金额</td>
            <td>{{ historyList.approveMoney }}</td>
            <td>放款账户</td>
            <td>{{ historyList.bankAccount }}</td>
          </tr>
          <tr v-if="IsStatus == '2'">
            <td>账户名</td>
            <td>{{ historyList.bankAccountName }}</td>
            <td>开户行</td>
            <td>{{ historyList.bankName }}</td>
          </tr>
        </tbody>
      </table>

      <!-- 法人身份证 -->
      <div class="LegalPersonIDCard">
        <div class="Left">
          <p class="title"><span>身份证人面像</span></p>
          <img :src="baseUrl + 'admin/file/get?ossFilePath='+historyList.userCardFrontImg" alt="" />
        </div>
        <div class="Right">
          <p><span>身份证国徽像</span></p>
          <img :src="baseUrl + 'admin/file/get?ossFilePath='+historyList.userCardBackImg" alt="" />
        </div>
      </div>
      <!-- 其他附件 -->
      <div class="OtherAccessories">
        <div class="title"><p>其他附件</p></div>
        <div class="textContent" v-if="historyList.files">
          <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
          <div
            class="Item"
            v-for="(item, index) in JSON.parse(historyList.files)"
            :key="index"
          >
            <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl">
              <div slot="error" class="image-slot">
                <i class="el-icon-document-copy"></i>
              </div>
            </el-image>
            <span>
              {{ item.FilesName }}
            </span>
          </div>
        </div>
        <div v-else class="textContent">暂无文件</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { operateDetails } from "@/api/RuralFinance";

export default {
  data() {
    return {
      dialogVisible2: true, //弹窗
      historyList: [], //弹窗内容
      IsStatus: "", //审核状态
      baseUrl: window.globalUrl.BASE_API,
    };
  },

  mounted() {},

  methods: {
    handleClose(done) {
      this.$emit("closeDialog2", false);
    },
    back() {
      this.$emit("closeDialog2", false);
    },
    // submit() {
    //   this.dialogVisible2 = false;
    // },
    //回显详情数据
    async init(val) {
      // console.log(val,"传过来的")
      let params = {
        applyId: val,
      };
      let res = await operateDetails(params);
      this.historyList = res.data;
      this.IsStatus = res.data.status;
      if (this.historyList.status == "-1") {
        this.historyList.status = "驳回";
      } else if (this.historyList.status == "0") {
        this.historyList.status = "未提交";
      } else if (this.historyList.status == "1") {
        this.historyList.status = "审批中";
      } else if (this.historyList.status == "2") {
        this.historyList.status = "通过";
      }
      this.historyList.operatList.forEach((item, index) => {
        console.log(item,'查看')
        if (item.operatUnit == "1") {
          item.operatUnit = "亩";
        } else {
          item.operatUnit = "平方米";
        }
      });

    },
  },
};
</script>

<style lang="less" scoped>
.LoanDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    tr {
      td {
        width: 80px;
        height: 30px;
        text-align: center;
      }
    }
  }
  /*.BusinessLicense {
    width: 100%;
    height: 100px;
    display: flex;

    span {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
    }
    .BusinessLicenseImg {
      margin-left: 100px;
      margin-top: 20px;
    }
  }*/
  /*法人身份证*/
  .LegalPersonIDCard {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    border: solid 1px black;
    border-top: none;
    border-bottom: none;
    padding: 5px 0px;
    .Left {
      width: 50%;
      display: flex;
      justify-content: space-around;
      img {
        width: 200px;
        height: 100px;
      }
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .title {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .Right {
      width: 50%;
      display: flex;
      justify-content: space-around;
      img {
        width: 200px;
        height: 100px;
      }
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .title {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
  /*其他附件*/
  .OtherAccessories {
    border: solid 1px black;
    display: flex;
    .title {
      width: 10%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      p {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        i {
          color: red;
        }
      }
    }
    .textContent {
      width: 100%;
      height: 120px;
      display: flex;
      border: solid 1px #ccc;
      .Item {
        width: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        padding: 10px;
        .el-image {
          width: 150px;
          height: 85px;
          background: #e6e3e3;
          img {
            width: 200px;
            height: 50px;
          }
          i {
            font-size: 50px;
            font-weight: bold;
            margin-top: 20px;
          }
        }
        span {
          margin-top: 5px;
        }
        .del {
          width: 20px;
          height: 20px;
          border: solid 1px black;
          border-radius: 50px;
          display: none;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 5000;
          text-align: center;
          line-height: 20px;
        }
      }
      .Item:hover {
        .del {
          display: block;
        }
      }
      .upload-demo {
        i {
          font-size: 50px;
          font-weight: bold;
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
