<template>
  <div class="application">
    <!-- 公司申请编辑 -->
    <div class="application">
      <!-- 申请详情 -->
      <el-dialog
        title="贷款申请"
        :visible.sync="dialogVisible3"
        :before-close="handleClose"
        width="70%"
      >
      <el-form
      :inline="true"
      :model="form"
      label-position="left"
      :rules="rules"
      ref="ruleForm"
    >
        <div class="LoanApplication" :model="form">
          <!-- 服务网点 -->
          <div class="serviceOutlets">
              <el-form-item label="服务网点">
                <el-select
                  value-key="departId"
                  v-model="form.OrganizationItem"
                  @change="$forceUpdate()"
                  style="width: 350px"
                >
                  <el-option
                    v-for="item in institutions"
                    :key="item.departId"
                    :label="item.departName"
                    :value="item"
                  ></el-option
                ></el-select>
              </el-form-item>

          </div>

          <!-- 基础信息 -->
          <div class="BasicInformation">
            <div class="title">
              <p>基础信息</p>
            </div>
            <div class="textContent">
              <div class="Left">
                <!-- <p>  :rules="rules"-->

                  <el-form-item label="主体类型">
                    <el-select v-model="form.userType" style="width: 200px">
                      <el-option
                        v-for="(item, index) in types"
                        :key="index"
                        :value="item.value"
                        :label="item.label"
                      ></el-option
                    ></el-select>
                  </el-form-item>
                  <el-form-item label="申请主体名称">
                    <el-input
                      v-model="form.ApplicationSubjectName"
                      :disabled=true
                      placeholder=""
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="所在地址">
                    <el-cascader
                      style="width: 190px"
                      ref="cascader"
                      v-model="form.areaCode"
                      :props="props"
                      :options="options"
                      :show-all-levels="false"
                    ></el-cascader>
                  </el-form-item>
                  <el-form-item label="法人姓名">
                    <el-input
                      v-model="form.LegalPersonName"
                      :disabled=true
                      placeholder=""
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="授权代理人">
                    <el-input
                      v-model="form.agentName"
                      placeholder=""
                    ></el-input>
                  </el-form-item>

              </div>

              <div class="Right">

                  <el-form-item label="统一社会信用代码">
                    <el-input
                      v-model="form.SocialCreditId"
                      :disabled=true
                      placeholder=""
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="手机号">
                    <el-input v-model="form.phone"   :disabled=true placeholder=""></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="填写详细地址">
                    <el-input
                      v-model="form.identityId"
                      placeholder=""
                    ></el-input>
                  </el-form-item> -->
                  <el-form-item label="法人身份证号">
                    <el-input
                      v-model="form.legalPersonNo"
                      placeholder=""
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="代理人身份证号">
                    <el-input v-model="form.agentNo" placeholder=""></el-input>
                  </el-form-item>

              </div>
            </div>
          </div>

          <!-- 经营信息 -->
          <div class="ManagementInformation">
            <div class="title">
              <p>经营信息</p>
            </div>
            <div class="textContent">
              <div class="textContent_top">
                <div class="Left">
                    <el-form-item label="年经营收入"  prop="income">
                      <el-input v-model="form.income" placeholder=""></el-input>
                    </el-form-item>
                    <p>元</p>
                  <p>{{ BigYearIncome }}</p>
                </div>
                <div class="Right">
                    <el-form-item label="实际经营年限" prop="YearManagement">
                      <el-input
                        v-model="form.YearManagement"
                        placeholder=""
                      ></el-input>
                    </el-form-item>

                  <p>年</p>
                </div>
              </div>
              <div class="textContent_bottom">
                <span>经营内容</span>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="form.BusinessContent"
                >
                </el-input>
              </div>
            </div>
          </div>

          <!-- 贷款信息 -->
          <div class="LoanInformation">
            <div class="title">
              <p>贷款信息</p>
            </div>
            <div class="textContent">
              <div class="LoanInformation_top">
                <div class="Left">
                    <el-form-item label="贷款金额"  prop="LoansAmount">
                      <el-input
                      :class="showMessage ? 'active' :''"
                      @blur="clearMessage"
                      @focus="clearMessage"
                        v-model="form.LoansAmount"
                        placeholder=""
                      ></el-input>
                      <div v-show="showMessage"
                      style="color: #f56c6c;  font-size: 12px;
                     line-height: 1;
                     padding-top: 4px;
                     position: absolute;
                     top: 100%;
                     left: 0;" class="item__error">{{promptMessage}}</div>
                    </el-form-item>

                  <p>{{ bigNumber }}</p>
                </div>
              </div>
              <div class="LoanInformation_bottom">
                <span>资金用途</span>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="form.FundsUse"
                >
                </el-input>
              </div>
            </div>
          </div>

          <!-- 营业执照 -->
          <div class="BusinessLicense">
            <div class="title">
              <p>营业执照<i>*</i></p>
            </div>
            <div class="textContent">
              <el-image
                v-if="logoUrl3"
                :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl3"
              >
                <div slot="error" class="image-slot">
                  <img src="../icon/imgError.png" />
                </div>
              </el-image>
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                name="files"
                :auto-upload="false"
                :on-change="handleChange"
                :file-list="fileList"
                :show-file-list="false"
              >
                <p><el-button type="primary">更换</el-button></p>
              </el-upload>
            </div>
          </div>

          <!-- 法人身份证 -->
          <div class="LegalPersonIDCard">
            <div class="title">
              <p>法人身份证人面像</p>
            </div>
            <div class="Left">
              <!-- <img
                :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl2"
                alt="暂无图片"
              /> -->
              <el-image
                v-if="logoUrl2"
                :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl2"
              >
                <div slot="error" class="image-slot">
                  <img src="../icon/imgError.png" />
                </div>
              </el-image>
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                name="files"
                :auto-upload="false"
                :on-change="handleChange2"
                :file-list="fileList"
                :show-file-list="false"
              >
                <p><el-button type="primary">更换</el-button></p>
              </el-upload>
            </div>
            <div class="title">
              <p>法人身份证国徽像</p>
            </div>
            <div class="Right">
              <!-- accept="|.png,.jpg," -->
              <!-- <img :src="logoUrl3" alt="暂无图片" /> -->
              <el-image
                v-if="logoUrl3"
                :src="baseUrl + 'admin/file/get?ossFilePath=' + logoUrl3"
              >
                <div slot="error" class="image-slot">
                  <img src="../icon/imgError.png" />
                </div>
              </el-image>
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :limit="6"
                name="files"
                :auto-upload="false"
                :on-change="handleChange3"
                :file-list="fileList"
                :show-file-list="false"
              >
                <p><el-button type="primary">更换</el-button></p>
              </el-upload>
            </div>
          </div>

          <!-- 其他附件 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>附件</p>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload
                ref="pic1"
                multiple
                class="upload-demo"
                :limit="6"
                :action="uploadUrl"
                name="files"
                :auto-upload="false"
                :on-remove="handleRemove"
                :on-change="handleChangeUp"
                :file-list="fileList"
                :show-file-list="false"
              >
                <i class="el-icon-download"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in files" :key="index">
                <div class="del" @click="del(index)">X</div>
                <el-image
                  :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl"
                  @click="AddFiles(item)"
                >
                  <div slot="error" class="image-slot">
                    <i
                      class="el-icon-document-copy"
                      @click="AddFiles(item)"
                    ></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>

        </div>
      </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button style="margin-top: 20px" @click="back">取消</el-button>
          <el-button style="margin-top: 20px" @click="SaveBtn"
            >保存草稿</el-button
          >
          <el-button type="primary" @click="submitBtn"> 提交 </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// 图片上传
import { uploadFile } from "@/api/fileApi";
//用户申请贷款
import {
  ApplicationLoan,
  AreaTree,
  operateDetails,
  selectDeptName,
  DetailsFinancialProducts,
} from "@/api/RuralFinance";
//数字大小写
import { digitUppercase } from "@/util/numConvert.js";
export default {
  props: {},
  data() {
     //判断贷款的额度是否到达标准
    //  var checkLoansAmount = (rule, value, callback) => {
    //   // console.log(value,'查看拿到的值')
    //     if (!value) {
    //       return callback(new Error('请输入贷款金额'));
    //     }
    //     setTimeout(() => {
    //         if (value > this.maxMoney) {
    //           callback(new Error('超出贷款额度'));
    //         } else if(value < this.minMoney) {
    //           callback('低于贷款额度');
    //       }
    //       else {
    //       callback();
    //     }
    //     }, 1000);
    //   };
    return {
      showApplyFor: false, //判断当前页面是农户还是公司
      fileList: [], //上传图片的列表,
      logoUrl: "", // 存储营业执照
      logoUrl2: "", // 存储人面像图片
      logoUrl3: "", // 存储国徽像图片
      dialogVisible3: true,
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
      form: {
        areaCode: "",
        area: "",
        userType: "", //主体类型
        ApplicationSubjectName: "", //申请主体名称
        Address: "", //所在地址
        LegalPersonName: "", //法人姓名
        agentName: "", //授权代理人
        phone: "", //手机号
        SocialCreditId: "", //统一社会信用代码
        // identityId: "", //填写详细地址
        legalPersonNo: "", //法人身份证号
        agentNo: "", //代理人身份证号
        income: "", //年经营收入
        YearManagement: "", //实际经营收入
        BusinessContent: "", //经营内容,
        LoansAmount: "", //贷款金额
        FundsUse: "", //资金用途
        OrganizationItem: "",
      }, //这是输入基础信息
      status: "", //保存还是发布
      options: [], // 所在地区
      financeProductId: "", //产品单号
      //主体类型
      types: [
        {
          label: "集体经济组织",
          value: "3",
        },
        {
          label: "农业企业",
          value: "41",
        },
        {
          label: "家庭农场",
          value: "42",
        },
        {
          label: "合作社",
          value: "43",
        },
      ],
      rules:{
        // LoansAmount:[
        //   { validator:checkLoansAmount, trigger: 'change' },
        // ],
        income:[{
          required: true, message: '请输入年经营收入', trigger: 'blur'
        }],
        YearManagement:[{
          required: true, message: '实际经营年限', trigger: 'blur'
        }],
      },
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },
      formRules: {
        univalentPrice: [
          { required: true, message: "请输入单价", trigger: "blur" },
        ],
      },
      institutions:[],
      baseUrl: window.globalUrl.HOME_API,
      files: [], //回显的图片
      applyId:'',
      maxMoney:'',
      minMoney:'',
      useDepartId:"",//回显的departId
      isTrueMoney:'',
      showMessage:false,//是否显示提示
      promptMessage:'',//提示语
    };
  },
  computed: {
    ...mapGetters(["identityObj", "topSelectAreacode"]),
    bigNumber() {
      if (this.form && this.form.LoansAmount) {
        return digitUppercase(this.form.LoansAmount);
      } else {
        return "";
      }
    },
    BigYearIncome() {
      if (this.form.income == 0) {
        return "";
      } else {
        return digitUppercase(this.form.income);
      }
    },
  },
  mounted() {
    // console.log(this.form, "这里有值嘛");
    //回显
    this.FirstShow();

  },

  methods: {
    //回显
    FirstShow() {
      //手机号
      this.form.phone =
        this.identityObj.contactPhone || this.identityObj.userName;
      //申请主体名称
      this.form.ApplicationSubjectName = this.identityObj.identityName;
      //信用代码
      this.form.SocialCreditId = this.identityObj.identityId;
      //法人姓名
      this.form.LegalPersonName = this.identityObj.bossName;
    },
    //申请详情的弹窗
    handleClose(done) {
      this.$emit("closeDialog3", false);
    },
    back() {
      this.$emit("closeDialog3", false);
    },
    clearMessage(){
        this.promptMessage="";
        this.showMessage=false;
    },
        // 查看金额
        maxMoneyTip(){
           // 检查输入框的值是否为空
     if (!this.form.LoansAmount.trim()||this.form.LoansAmount=='') {
        this.promptMessage = '请输入贷款金额';
        this.showMessage = true;
        return;
      }

      // 检查输入框的值是否大于最大额度
      if (parseInt(this.form.LoansAmount) > this.maxMoney) {
        this.promptMessage = '超出贷款额度';
        this.showMessage = true;
        return;
      }

      // 检查输入框的值是否小于最小额度
      if (parseInt(this.form.LoansAmount) < this.minMoney) {
        this.promptMessage = '低于贷款额度';
        this.showMessage = true;
        return;
      }
  },
    //发布
    async submitBtn() {
      this.rules.income[0].required=true
      this.rules.YearManagement[0].required=true
      this.maxMoneyTip()
      this.$refs.ruleForm.validate(async (valid, obj) => {
      if(valid ||!this.form.LoansAmount.trim()||!this.form.LoansAmount==''){
        this.maxMoneyTip()
        if (this.showMessage) return;
      let area = this.$refs["cascader"].getCheckedNodes();
      this.form.area = area[0].data.fullPathName;
      this.status = 1;
      let params = {
        applyId: this.applyId,
        areaCode: this.form.areaCode,
        area: this.form.area,
        // userId: this.identityObj.identityId,
        userType: this.form.userType,
        name: this.form.ApplicationSubjectName,
        address: this.form.identityId,
        idCardNo: this.form.SocialCreditId,
        legalPersonNo: this.form.legalPersonNo,
        phone: this.form.phone,
        userCardFrontImg: this.logoUrl2,
        userCardBackImg: this.logoUrl3,
        files: JSON.stringify(this.files),
        income: this.form.income,
        operatYear: this.form.YearManagement,
        money: this.form.LoansAmount,
        use: this.form.FundsUse,
        status: this.status,
        legalPersonName: this.form.LegalPersonName,
        LegalPersonId: this.form.LegalPersonId,
        agentName: this.form.agentName,
        agentNo: this.form.agentNo,
        operatIntorducation: this.form.BusinessContent,
        licenseImg: this.logoUrl,
        financeProductId: this.form.OrganizationItem.financeProductId, //产品ID
        departId: this.form.OrganizationItem.departId,
        departName: this.form.OrganizationItem.departName,
      };
      let res = await ApplicationLoan(params);
      this.$emit("closeDialog3", false);
    }else{
        this.$message.error('请完善提交信息')
      }
      })
    },
    //用户申请贷款
    async SaveBtn() {
      // this.rules.LoansAmount.push({required:false})
      this.rules.income[0].required=false
      this.rules.YearManagement[0].required=false
      // this.$refs.ruleForm.validate(async (valid, obj) => {
      // if(valid){

      let area = this.$refs["cascader"].getCheckedNodes();
      this.form.area = area[0].data.fullPathName;
      this.status = 0;
      let params = {
        applyId: this.applyId,
        areaCode: this.form.areaCode,
        area: this.form.area,
        // userId: this.identityObj.identityId,
        userType: this.form.userType,
        name: this.form.ApplicationSubjectName,
        address: this.form.Address,
        idCardNo: this.form.SocialCreditId,
        phone: this.form.phone,
        userCardFrontImg: this.logoUrl2,
        userCardBackImg: this.logoUrl3,
        fileList: this.fileList,
        income: this.form.YearIncome,
        operatYear: this.form.YearManagement,
        money: this.form.LoansAmount,
        use: this.form.FundsUse,
        status: (this.status = 1),
        legalPersonName: this.form.LegalPersonName,
        LegalPersonId: this.form.LegalPersonId,
        agentName: this.form.AuthorizedAgent,
        agentNo: this.form.AuthorizedAgentId,
        operatIntorducation: this.form.BusinessContent,
        licenseImg: this.logoUrl,
        financeProductId: this.form.OrganizationItem.financeProductId, //产品ID
        departId: this.form.OrganizationItem.departId,
        departName: this.form.OrganizationItem.departName,

      };
      let res = await ApplicationLoan(params);

      // 关闭弹窗

      // 跳转到申请记录页面
      if (res.code == 0) {
        this.$message.success(res.msg);
        this.$router.push("/memberCenter/financalService/applicationRecord");
        this.$emit("closeDialog3", false);
      }else{
        this.$message.info(res.msg);
      }

    // else{
    //     this.$message.error('请完善提交信息')
    //   }
      // })

    },
    //上传
    async allUpLoad(file, type, num) {
      let param = new FormData();
      param.append("catalog", type);
      param.append("file", file.raw);
      let res = await uploadFile(param);
      let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;

      if (num == 1) {
        this.logoUrl = imgUrl;
      } else if (num == 2) {
        this.logoUrl2 = imgUrl;
      } else {
        this.logoUrl3 = imgUrl;
      }
    },
    //上传营业执照
    handleChange(file) {
      this.allUpLoad(file, "BusinessLicense", 1);
    },
    //上传法人身份证人面像
    handleChange2(file) {
      this.allUpLoad(file, "LegalPersonIDCardL", 2);
    },
    //上传法人身份证国徽像
    handleChange3(file) {
      this.allUpLoad(file, "LegalPersonIDCardR", 3);
    },

    //获取回显的数据
    async editObj(ids) {

      //获取详情数据
      let res = await operateDetails({ applyId: ids.applyId });
      let obj = res.data;
      this.financeProductId = ids.financeProductId; //产品ID
      this.form.ApplicationSubjectName = obj.name; // 申请主体名称
      this.form.userType = obj.userType; //主题类型
      // this.form.Address=obj.Address//所在地址
      this.form.LegalPersonName = obj.legalPersonName; //法人姓名
      this.form.agentName = obj.agentName; //授权代理人
      this.form.phone = obj.phone; //手机号
      this.form.SocialCreditId = obj.idCardNo; //统一社会信用代码
      // this.form.identityId = obj.address; //填写详细地址
      this.form.legalPersonNo = obj.legalPersonNo; //法人身份证号
      this.form.agentNo = obj.agentNo; //代理人身份证号
      this.form.income = obj.income; //年经营收入
      this.form.YearManagement = obj.operatYear; //实际经营收入
      this.form.BusinessContent = obj.operatIntorducation; //经营内容,
      this.form.LoansAmount = obj.money; //贷款金额
      this.form.FundsUse = obj.use; //资金用途
      this.logoUrl = obj.licenseImg; // 存储营业执照
      this.logoUrl2 = obj.userCardFrontImg; // 存储人面像图片
      this.logoUrl3 = obj.userCardBackImg; // 存储国徽像图片
      this.files = JSON.parse(obj.files) || []; //上传图片的列表,
      this.useDepartId=ids.departItem.departId//查询需要回显的服务网点
      this.form.areaCode = res.data.areaCode;
      this.applyId=res.data.applyId

      this.form.OrganizationItem=ids.departItem
      this.findMoney()
      //获取所在地区
      let res2 = await AreaTree({ parentCode: this.topSelectAreacode });
      this.options = res2.data;
      //查询所属机构
      this.selectDeptName()
      this.findMoney()
      //显示地址
      setTimeout(() => {
        this.form.areaCode = obj.areaCode;
      }, 1000);
    },
    //查询该产品的最大、最小贷款额度
    async findMoney() {
      let params = {
        financeProductId: this.financeProductId,
      };
      let res = await DetailsFinancialProducts(params);
      this.maxMoney = res.data[0].maxMoney
      this.minMoney = res.data[0].minMoney
    },
    //文件上传
    async handleChangeUp(file, fileList) {
      console.log(fileList, "这是fileList");
      let param = new FormData();
      param.append("catalog", "financeAdd");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      console.log(res, "这是res");
      // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
      let FilesName = res.data.sourceFileName;
      let imgUrl = res.data.url;
      let item = {
        FilesName: FilesName,
        imgUrl: imgUrl,
      };
      this.files.push(item);
      console.log(this.files);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    //附件上传图片的删除
    del(index) {
      let newFiles = this.files.filter((item, ind) => {
        if (index == ind) {
          let newFiles = this.files.splice(ind, 1);
          return newFiles;
        }
      });
      console.log(newFiles);
    },
    //附件下载功能
    async AddFiles(item) {
      let dom = document.createElement("a");
      dom.style.display = "none";
      dom.href = "/api/admin/file/get?ossFilePath=" + item.imgUrl;
      dom.setAttribute("download", item.FilesName);
      document.body.appendChild(dom);
      dom.click();
    },
    //查询所属机构
    async selectDeptName() {
      console.log('需要执行查看是否执行',this.applyId)
      let params = {
        financeProductId: this.financeProductId ,

      };
      let res = await selectDeptName(params);
       res.data.filter(item=>{
       if(item.departId==this.useDepartId){
           return this.form.OrganizationItem=item
       }
      })
      this.institutions =res.data;
    }
  },
};
</script>

<style lang="less" scoped>
.application {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .LoanApplication {
    width: 100%;
    height: 100%;
    /*服务网点*/
    .serviceOutlets {
      .el-form {
        margin-left: 26px;
      }
      /deep/.el-form-item {
        margin-top: 20px;
        .el-form-item__label {
          width: 96px;
          height: 40px;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
        .el-form-item__content {
          width: 352px;
        }
      }
      /*是否平移*/
      /* /deep/.el-form-item__content{
              margin-left: 119px;
            }*/
    }
    /*基础信息*/
    .BasicInformation {
      display: flex;
      border: solid 1px #cccc;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
      }
      .textContent {
        width: 88%;
        display: flex;
        padding: 10px;
        .Left {
          width: 50%;
          height: 100%;
          /deep/.el-form-item {
            width: 100%;
            .el-input {
              width: 200px;
            }
            .el-form-item__label {
              width: 96px;
              height: 40px;
            }
          }
        }
        .Right {
          width: 50%;
          height: 100%;
          /deep/.el-form-item {
            width: 100%;
            .el-input {
              width: 200px;
            }
            .el-form-item__label {
              width: 124px;
              height: 40px;
            }
          }
        }
      }
    }
    /*经营信息*/
    .ManagementInformation {
      display: flex;
      border: solid 1px #cccc;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
      }
      .textContent {
        width: 80%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        .textContent_top {
          width: 100%;
          display: flex;

          .Left {
            width: 45%;
            display: flex;
            p {
              display: flex;
              justify-content: space-between;
              padding: 0px 5px;
              span {
                i {
                  color: red;
                }
              }
            }
            /deep/.el-form-item {
              display: flex;
              .el-input {
                width: 150px;
              }
              .el-form-item__label {
                width: 96px;
                height: 40px;
              }
            }
          }
          .Right {
            width: 45%;
            display: flex;
            /deep/.el-form-item{
              .el-form-item__label{
                width: 120px !important;
              }
        }
            p {
              display: flex;
              justify-content: space-between;
              span {
                i {
                  color: red;
                }
              }
            }
            /deep/.el-form-item {
              display: flex;
              .el-input {
                width: 150px;
              }
              .el-form-item__label {
                width: 96px;
                height: 40px;
              }
            }
          }
        }
        .textContent_bottom {
          width: 100%;
          display: flex;
          span {
            width: 100px;
            margin: 0px 2px;
            line-height: 50px;
          }
        }
      }
    }
    /*贷款信息*/
    .LoanInformation {
      display: flex;
      border: solid 1px #cccc;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
      }
      .textContent {
        width: 80%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        .LoanInformation_top {
          .Left {
            display: flex;
            /deep/.el-form-item {
              display: flex;
              .el-input {
                width: 200px;
              }
              .el-form-item__label {
                width: 96px;
                height: 40px;
              }
            }
            p {
              margin-left: 10px;
            }
            .Exceed {
              color: red;
            }
          }
        }
        .LoanInformation_bottom {
          width: 100%;
          display: flex;
          span {
            width: 100px;
            line-height: 50px;
          }
        }
      }
    }
    /*营业执照*/
    .BusinessLicense {
      display: flex;
      border: solid 1px #ccc;
      padding: 10px;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
        }
      }
      .textContent {
        width: 48%;
        display: flex;
        justify-content: space-around;
        padding-left: 10px;
        /deep/.el-image {
          img {
            width: 200px;
            height: 100px;
            padding: 5px 0px;
          }
          .image-slot {
            img {
              width: 100px;
              height: 80px;
            }
          }
        }
        img {
          width: 200px;
          height: 100px;
        }
        p {
          width: 94px;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }
    }
    /*法人身份证*/
    .LegalPersonIDCard {
      display: flex;
      border: solid 1px #ccc;
      padding: 10px;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          i {
            color: red;
          }
        }
      }
      .Left {
        width: 50%;
        display: flex;
        padding-left: 50px;
        justify-content: space-around;
        /deep/.el-image {
          img {
            width: 200px;
            height: 100px;
            padding: 5px 0px;
          }
          .image-slot {
            img {
              width: 100px;
              height: 80px;
            }
          }
        }

        p {
          width: 94px;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .title {
          font-size: 15px;
          font-weight: bold;
        }
      }
      .Right {
        width: 50%;
        display: flex;
        justify-content: space-around;
        /deep/.el-image {
          img {
            width: 200px;
            height: 100px;
            padding: 5px 0px;
          }
          .image-slot {
            img {
              width: 100px;
              height: 80px;
            }
          }
        }
        p {
          width: 94px;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
        .title {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
    /*其他附件*/
    .OtherAccessories {
      border: solid 1px #ccc;
      display: flex;
      .title {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          i {
            color: red;
          }
        }
      }
      .textContent {
        width: 100%;
        height: 120px;
        display: flex;
        border: solid 1px #ccc;
        .Item {
          width: 150px;
          height: 100%;
          display: flex;
          flex-direction: column;
          text-align: center;
          position: relative;
          padding: 10px;
          .el-image {
            width: 150px;
            height: 85px;
            background: #e6e3e3;
            img {
              width: 200px;
              height: 50px;
            }
            i {
              font-size: 50px;
              font-weight: bold;
              margin-top: 20px;
            }
          }
          span {
            margin-top: 5px;
          }
          .del {
            width: 20px;
            height: 20px;
            border: solid 1px black;
            border-radius: 50px;
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 5000;
            text-align: center;
            line-height: 20px;
          }
        }
        .Item:hover {
          .del {
            display: block;
          }
        }
        .upload-demo {
          i {
            font-size: 50px;
            font-weight: bold;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>
